<template>
  <!--  services section -->
  <div>
    <section class="w3l-index1">
      <div class="features-with-17_sur py-5">
        <div class="container py-lg-3">
          <div class="header-section text-center">
            <h3>我们的优势</h3>
            <p class="mt-3 mb-5">我们的产品对用户产生了实质性的帮助，用户从中提高了效率，节省了成本，完善了生产体系，他们获得了相当可观的利润。</p>
          </div>
          <div class="features-with-17-top_sur">
            <div class="row">
              <div class="col-lg-4 col-md-6 m-top">
                <div class="features-with-17-right-tp_sur">
                  <div class="features-with-17-left1">
                    <span class="fa fa-industry" aria-hidden="true"></span>
                  </div>
                  <div class="features-with-17-left2">
                    <h6><a href="/#/services">机械工程</a></h6>
                    <p>提高生产效率，降低人力成本，提升企业利润</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 m-top">
                <div class="features-with-17-right-tp_sur">
                  <div class="features-with-17-left1">
                    <span class="fa fa-power-off" aria-hidden="true"></span>
                  </div>
                  <div class="features-with-17-left2">
                    <h6><a href="/#/services">节约能源</a></h6>
                    <p>减少能源浪费，提高企业竞争力</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 m-top">
                <div class="features-with-17-right-tp_sur">
                  <div class="features-with-17-left1">
                    <span class="fa fa-map-o" aria-hidden="true"></span>
                  </div>
                  <div class="features-with-17-left2">
                    <h6><a href="/#/services">工厂</a> </h6>
                    <p>减少人力成本，优化生产效率，提高产品可靠性</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 m-top">
                <div class="features-with-17-right-tp_sur">
                  <div class="features-with-17-left1">
                    <span class="fa fa-modx" aria-hidden="true"></span>
                  </div>
                  <div class="features-with-17-left2">
                    <h6><a href="/#/services">环境保护</a></h6>
                    <p>合理利用资源，降低碳排放，保护地球环境</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 m-top">
                <div class="features-with-17-right-tp_sur">
                  <div class="features-with-17-left1">
                    <span class="fa fa-podcast" aria-hidden="true"></span>
                  </div>
                  <div class="features-with-17-left2">
                    <h6><a href="/#/services">生产自动化</a></h6>
                    <p>提升企业核心竞争力，帮助企业走在科技前沿</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 m-top">
                <div class="features-with-17-right-tp_sur">
                  <div class="features-with-17-left1">
                    <span class="fa fa-bar-chart" aria-hidden="true"></span>
                  </div>
                  <div class="features-with-17-left2">
                    <h6><a href="/#/services">研发新产品</a> </h6>
                    <p>紧追尖端科技，利用高新技术</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="w3l-index2">
      <div class="row abouthy-img-grids no-gutters">
        <div class="col-lg-4 col-md-6 img-one">
          <img src="https://o.stjan.cn/assets/images/img4.jpg" alt=" " class="img-fluid">
        </div>
        <div class="col-lg-4 col-md-6 img-one content-mid py-md-0 py-5">
          <div class="info">
            <h3>
              开发团队
            </h3>
            <p class="mt-3 mb-5 white">我们拥有强大的硬件和软件开发团队，能为客户深度定制个性方案。
            </p>
            <a href="/#/about" class="btn btn-light theme-button">关于我们</a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 img-one">
          <img src="https://o.stjan.cn/assets/images/img2.jpg" alt=" " class="img-fluid">
        </div>
        <div class="col-lg-4 col-md-6 img-info content-mid py-md-0 py-5">
          <div class="info">
            <h3>
              生产自动化
            </h3>
            <p class="mt-3 mb-5">专业的生产流程，产品可靠稳定。</p>
            <a href="/#/services" class="btn btn-primary theme-button">服务体系</a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 img-one">
          <img src="https://o.stjan.cn/assets/images/img8.jpg" alt=" " class="img-fluid">
        </div>
        <div class="col-lg-4 col-md-6 img-info content-mid py-md-0 py-5">
          <div class="info">
            <h3>
              提高效率
            </h3>
            <p class="mt-3 mb-5">能为企业带来实质性的效益，效率就是金钱。</p>
            <a href="/#/contact" class="btn btn-primary theme-button">联系我们</a>
          </div>
        </div>
      </div>
    </section>
    <div class="w3l-index3 pt-5" id="about">
      <div class="container pt-lg-3">
        <div class="header-section text-center">
          <h3>我们的使命</h3>
          <p class="mt-3 mb-5">我们致力于传统制造业的生产自动化信息化改造，帮助企业降低成本，提升竞争力。</p>
        </div>
        <div class="aboutgrids row">
          <div class="col-lg-6 aboutgrid1">
            <h4>我们用行动改变世界</h4>
            <p>我们帮助的每一个企业，都将为社会创造更大的价值</p>
            <ul class="services-list mb-5">
              <li>产品与解决方案</li>
              <li>定制型解决方案</li>
              <li>减少能耗可靠运行</li>
              <li>改善设备操作条件</li>
              <li>降低企业自身资源消耗</li>
            </ul>
            <a href="/#/services" class="btn btn-primary theme-button">更多服务</a>
          </div>
          <div class="col-lg-6 aboutgrid2 mt-lg-0 mt-5">
            <img src="https://o.stjan.cn/assets/images/about.jpg" alt="about image" class="img-fluid" />
          </div>
        </div>
      </div>
      <div class="aboutbottom py-5 bg bg2">
        <div class="container py-lg-3">
          <div class="bottomgrids row">
            <div class="col-lg-3 col-md-6 bottomgrid1 mt-lg-0 mt-0 propClone">
              <h4><a href="/#/services">生产自动化</a></h4>
              <p>为企业定制经济可行的生产自动化改造方案，提升产能，减少人力成本。</p>
            </div>
            <div class="col-lg-3 col-md-6 bottomgrid1 mt-md-0 mt-5 propClone">
              <h4><a href="/#/services">信息化工程</a></h4>
              <p>生产数据实时采集，企业管理者可以第一时间获得信息，快速决策。</p>
            </div>
            <div class="col-lg-3 col-md-6 bottomgrid1 mt-lg-0 mt-5 propClone">
              <h4><a href="/#/services">设备智能化</a></h4>
              <p>提供旧设备的智能化改造方案，节约成本，避免资源浪费。</p>
            </div>
            <div class="col-lg-3 col-md-6 bottomgrid1 mt-lg-0 mt-5 propClone">
              <h4><a href="/#/services">效能管理</a></h4>
              <p>生产数据接入管理系统，提供数据分析和预警，便于管理者提前准备，减少误工情况。</p>
            </div>
            <div class="clearfix"> </div>
          </div>
        </div>
      </div>
    </div>
    <section class="w3l-index4">
      <!-- /features -->
      <div class="features py-5">
        <div class="container py-lg-3">
          <div class="header-section white text-center">
            <h3>定制专属功能</h3>
            <p class="mt-3 mb-5">每个企业的情况各不相同，我们可为企业制定其专属的解决方案，具体问题具体解决。</p>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="feature-gd">
                <div class="icon"> <span class="fa fa-map-o" aria-hidden="true"></span></div>
                <div class="icon-info">
                  <h5 class="my-3"><a href="/#/services">产品原料</a></h5>
                  <p> 根据客户的需要，选用指定的原材料生产</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="feature-gd">
                <div class="icon"> <span class="fa fa-power-off" aria-hidden="true"></span></div>
                <div class="icon-info">
                  <h5 class="my-3"><a href="/#/services">节能优化</a></h5>
                  <p> 适用对功耗要求特别敏感的场合</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="feature-gd">
                <div class="icon"> <span class="fa fa-podcast" aria-hidden="true"></span></div>
                <div class="icon-info">
                  <h5 class="my-3"><a href="/#/services">度身定制</a></h5>
                  <p> 根据实际环境，产品作适应性的改造</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="feature-gd">
                <div class="icon"> <span class="fa fa-bar-chart" aria-hidden="true"></span></div>
                <div class="icon-info">
                  <h5 class="my-3"><a href="/#/services">软件开发</a></h5>
                  <p> 为企业定制专属管理系统</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="feature-gd">
                <div class="icon"> <span class="fa fa-industry" aria-hidden="true"></span></div>
                <div class="icon-info">
                  <h5 class="my-3"><a href="/#/services">硬件开发</a></h5>
                  <p> 为企业定制专属设备及部件</p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="feature-gd">
                <div class="icon"> <span class="fa fa-modx" aria-hidden="true"></span></div>
                <div class="icon-info">
                  <h5 class="my-3"><a href="/#/services">增值服务</a></h5>
                  <p> 方案定制，员工培训等服务</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //features -->
    </section>
  </div>
  <!--  //services section -->
</template>

<script>
export default {
  name: "ServicesSection"
}
</script>

<style scoped>

</style>
