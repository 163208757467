<template>
  <section class="w3l-main-slider">
    <!-- main-slider -->
    <div class="companies20-content">
      <div class="companies-wrapper">
        <el-carousel
          height="600px"
        >
          <el-carousel-item>
            <div class="slider-info banner-view">
              <div class="banner-info container" style="text-align: center">
                <p class="slide-one">我们致力于解决</p>
                <h3 class="banner-text mt-lg-5 mt-md-4 mt-3">极具挑战性的工程项目</h3>
                <a href="/#/about" class="btn btn-primary theme-button">关于我们</a>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="slider-info  banner-view banner-top1">
              <div class="banner-info container" style="text-align: center">
                <p class="slide-two">定制化工业4.0改造</p>
                <h3 class="banner-text mt-lg-5 mt-md-4 mt-3">帮助行业组织和企业引领潮流 </h3>
                <a href="/#/services" class="btn btn-primary theme-button">服务体系</a>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="slider-info banner-view banner-top2">
              <div class="banner-info container" style="text-align: center">
                <h3 class="banner-text mb-0">先进的工业与制造业经营模式</h3>
                <p class="slide-three mt-4 mb-md-5 mb-4">为企业创造更多利润</p>
                <a href="/#/contact" class="btn btn-primary theme-button">联系我们</a>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- /main-slider -->
  </section>
</template>

<script>
export default {
  name: "MainSlider",
  created() {
    this.slide()
  },
  methods: {
    slide() {
      // TODO: 用element ui完成导航栏轮播
    }
  }
}
</script>

<style scoped>

</style>
