<template>
  <section class="w3l-index5">
    <div class="new-block top-bottom">
      <div class="container">
        <div class="middle-section">
          <div class="section-width">
            <h5>目标：成为航天级的企业</h5>
            <h2>向星辰大海进发</h2>
          </div>
          <div class="link-list-menu">
            <p class="mb-5">我们的目标是将产品和项目都做到高稳定性和可靠性，真真切切为用户带来实实在在的价值。</p>
            <a href="/#/about" class="btn btn-primary theme-button">了解更多 </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MiddleSection"
}
</script>

<style scoped>

</style>
