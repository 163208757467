<template>
  <section class="w3l-testimonials" id="testimonials">
    <div class="testimonials py-5">
      <div class="container py-lg-5">
        <div class="header-section text-center">
          <h3>客户评价</h3>
          <p class="mt-3 mb-5">我们的产品和服务受到了客户的广泛好评</p>
        </div>
        <div class="row mt-4">
          <div class="col-md-10 mx-auto">
            <el-carousel
              height="400px"
            >
              <el-carousel-item
                v-for="item in list"
                :key="item.name"
              >
                <div class="slider-info mt-lg-4 mt-3">
                  <div class="img-circle">
                    <img :src="item.url" class="img-fluid testimonial-img" alt="client image">
                  </div>
                  <div class="message">
                    <span class="fa fa-quote-left" aria-hidden="true"></span>
                    <p>{{ item.message }}</p>
                    <div class="name mt-4">
                      <h4>{{ item.name }}</h4>
                      <p>{{ item.posiiton }}</p>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Testimonials",
  data() {
    return {
      list: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.list = [
        { name: '卢先生', position: '植物工厂操作员', message: '星舰公司给我们定制了一款轨道式载重运输车，帮助我们解决了植物工厂的运输问题，使用至今已有3年，一直可靠稳定，有任何疑问都能及时得到反馈。', url: 'https://o.stjan.cn/assets/images/client1.jpg' },
        { name: '邝小姐', position: '玩具产品开发部', message: '之前委托了贵司开发电子模块，贵司一直都非常配合我司的开发步调，服务态度非常好，最后因一些缘由没能合作上，挺遗憾的。期待下次的合作！', url: 'https://o.stjan.cn/assets/images/client2.jpg' },
        { name: '梁总', position: '顺力科技CEO', message: '贵司开发的自动售货机非常新颖，成功为我司带来了几十倍的估值增量，希望以后继续合作！', url: 'https://o.stjan.cn/assets/images/client3.jpg' },
        { name: '刘总', position: '凌谷科技CEO', message: '贵司开发的物联网终端，配套我司的生产管理系统，一举拿下了客户的大订单，非常不错！', url: 'https://o.stjan.cn/assets/images/client4.jpg' }
      ]
    }
  }
}
</script>

<style scoped>

</style>
