<template>
  <section class="w3l-logos py-5">
    <div class="container py-lg-3">
      <div class="header-section text-center">
        <h3>合作企业遍布全国各地</h3>
        <p class="mt-3 mb-5">我们的合作客户</p>
      </div>
      <div class="row">
        <div
          class="col-lg-3 col-6 logo-view"
          v-for="item in list"
          :key="item.name"
        >
          <img :src="item.url" alt="company-logo" class="img-fluid" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PartnersList",
  data() {
    return {
      list: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.list = [
        { name: 'shunli', url: 'https://o.stjan.cn/assets/images/logo_shunli.jpg' },
        { name: 'zhongsoft', url: 'https://o.stjan.cn/assets/images/logo_zhongcheng.jpg' },
        { name: 'linkgoo', url: 'https://o.stjan.cn/assets/images/logo_linkgoo.jpg' },
        { name: 'dahua', url: 'https://o.stjan.cn/assets/images/logo_dahua.jpg' },
        { name: 'mefo', url: 'https://o.stjan.cn/assets/images/logo_mefo.jpg' },
        { name: 'yuehua', url: 'https://o.stjan.cn/assets/images/logo_yuehua.jpg' }
      ]
    }
  }
}
</script>

<style scoped>

</style>
