<template>
  <!-- subscribe form -->
  <section class="w3l-subscribe">
    <div class="main-w3 py-5">
      <div class="container py-lg-3">
        <div class="grids-forms text-center">
          <div class="row">
            <div class="col-md-12">
              <span class="fa fa-envelope mb-3" aria-hidden="true"></span>
              <div class="header-section white">
                <h3>关注我们新的动态</h3>
                <p>接收最新消息</p>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-7 mx-auto main-midd-2">
              <form action="#" method="post" class="rightside-form">
                <input type="email" name="email" placeholder="您的电子邮箱..." required>
                <button type="submit" class="btn btn-primary theme-button">订阅</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- //subscribe form -->
</template>

<script>
export default {
  name: "SubscribeForm"
}
</script>

<style scoped>

</style>
