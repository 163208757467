<template>
  <!-- blog with slider -->
  <section class="blog-slider py-5" id="blog">
    <div class="container py-lg-4">
      <div class="header-section text-center">
        <h3>最新动态</h3>
        <p class="mt-3 mb-5">请关注我们的微博，了解更多信息</p>
      </div>
      <div class="inner-sec-w3layouts mt-md-5 mt-4" style="align-items: center;">
        <el-carousel
          height="620px"
        >
          <el-carousel-item
            v-for="item in list"
            :key="item.title"
          >
            <div class="content-left-sec" style="background: white; padding: 15px">
              <a class="blog-link d-block zoom-image" href="#blog-single.html"><img :src="item.url" class="img-fluid scale-image" alt=""></a>
              <div class="blog-info">
                <h4 class="mb-0"><a href="#blog-single.html">
                  {{ item.title }}
                </a></h4>
                <h6 class="mt-sm-2 mt-1">{{ item.date }}</h6>
                <a href="#blog-single.html" class="category">{{ item.type }}</a>
                <p>
                  {{ item.content }}</p>
                <a href="#blog-single.html" class="btn btn-primary theme-button">查看原文 </a>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </section>
  <!-- //blog with slider -->
</template>

<script>
export default {
  name: "BlogWithSlider",
  data() {
    return {
      list: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.list = [
        { title: '一文读懂工业4.0模式将如何影响世界', url: 'https://o.stjan.cn/assets/images/img4.jpg', date: 'Dec 6th, 2020 -', type: 'Industry', content: '当前制造业面临着劳动力上涨，老龄化、经济放缓等多重挑战，同时全球制造竞争也在变得越来越激烈。德国提出了“工业4.0”战略，旨在通过先进的物理信息系统，连接通虚拟和现实世界，从而进一步提升整个制造过程的运营效率，帮助企业获得快速应对市场变化的能力。' },
        { title: '纽约→上海39分钟!SpaceX“星舰”2年内试飞', url: 'https://o.stjan.cn/assets/images/img5.jpg', date: 'Dec 7th, 2020 -', type: 'Industry', content: '乘坐SpaceX旗下“星舰(Starship)”,以27000公里的最高时速飞行,乘客在纽约时间早间7点出发,仅需39分钟就可以跨越11897公里的距离,在上海落地。' },
        { title: '迈向工业4.0时代从数字化转型开始', url: 'https://o.stjan.cn/assets/images/img6.jpg', date: 'Dec 8th, 2020 -', type: 'Industry', content: '伴随着数字经济时代的到来，互联网主体已经渗透到企业和全产业链条、全生命周期。' },
        { title: '星舰科技推出重量级微信小程序“有数可据”', url: 'https://o.stjan.cn/assets/images/img7.jpg', date: 'Dec 9th, 2020 -', type: 'Industry', content: '通过微信便捷的小程序，提升用户体验，增强数据实时性。' },
        { title: 'ABB：直面数字化转型的机遇与挑战', url: 'https://o.stjan.cn/assets/images/img8.jpg', date: 'Dec 10th, 2020 -', type: 'Industry', content: '当下，工业市场的内外部环境正在发生变化，既有积极因素，也有风险挑战。全球经济和产业结构调整产生的剧烈影响，从工业自动化领域几大巨头的动态上便可见一斑。' }
      ]
    }
  }
}
</script>

<style scoped>

</style>
