<template>
  <div>
    <main-slider />
    <services-section />
    <testimonials />
    <middle-section />
    <blog-with-slider />
    <subscribe-form />
    <partners-list />
  </div>
</template>

<script>
import {
  MainSlider,
  ServicesSection,
  Testimonials,
  MiddleSection,
  BlogWithSlider,
  SubscribeForm,
  PartnersList
} from './components'

export default {
  name: 'Home',
  components: {
    MainSlider,
    ServicesSection,
    Testimonials,
    MiddleSection,
    BlogWithSlider,
    SubscribeForm,
    PartnersList
  },
  data() {
    return {
      title: '主页 - 珠海市星舰科技有限公司'
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      document.title = this.title
    }
  }
}
</script>

<style scoped>

</style>
